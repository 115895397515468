<template>
    <div class="patient-editBind">
        <div class="editBind-main">
            <div class="last-patient">
                <p class="patient-name">{{$t("patient.patientname",{name:patientInfo.name?patientInfo.name:''})}}</p>
                <p class="patient-info">
                    <img :src="patientInfo.faceUrl" alt="">
                    <span class="account">{{$t("patient.account",{phone:patientInfo.tel?replacePhone(patientInfo.tel):''})}}</span>
                    <span class="info-tag">{{$t("patient.originalAccount")}}</span>
                </p>
            </div>
            <div class="main-icon"></div>
            <div class="last-patient logininfo">
                <p class="patient-name">{{$t("patient.descPatientLoginAccunt")}}</p>
                <p class="patient-info">
                    <img :src="$store.getters.avatar" alt="">
                    <span class="account">{{$t("patient.account",{phone:replacePhone($store.getters.tel)})}}</span>
                    <span class="info-tag">{{$t("patient.loginAccout")}}</span>
                </p>
            </div>
            <div class="bind-desc">
                {{$t("patient.descPatient",{phone:patientInfo.tel?replacePhone(patientInfo.tel):''})}}
            </div>
            <div class="edit-form">
                <div class="form-item">
                    <span class="label">{{$t("patient.Phone")}}</span>
                    <van-field v-model="patientInfo.tel" readonly type="number" @blur="phoneChange" :maxlength="11" :placeholder='$t("patient.pleasePhone")' />
                </div>
                <div class="form-item">
                    <span class="label">{{$t("patient.yzm")}}</span>
                    <van-field v-model="editForm.telVerifyCode" type="number" :maxlength="6" :placeholder='$t("patient.pleaseYzmcode")' />
                    <van-button type="primary" class="btn-yzm" @click="SecondaryVerification" size="small">{{timeNum == 0? $t("patient.getYzm"):$t("patient.yzmTime",{num:timeNum})}}</van-button>
                </div>
                <div class="form-item">
                    <span class="noyzm" @click="descShow = true">{{$t("patient.noReceiveYzm")}}</span>
                </div>
            </div>
        </div>
        <div class="edit-btn" :class="{'btnActive':dislablebtn}" @click="submit"> 
            {{$t("patient.submittxt")}}
        </div>
        <van-overlay :show="descShow" @click="descShow = false" class-name="mydesc">
            <div class="patientwrapper" @click.stop>
                <i class="close-icon" @click="descShow = false"></i> 
                <p class="title">
                    {{$t("patient.descTitle")}}
                </p>
                <div class="desc">
                    <p class="txt">
                        {{$t("patient.desctxt1")}}
                    </p>
                    <p class="txt">
                        {{$t("patient.desctxt2")}}
                    </p>
                    <p class="txt">
                        {{$t("patient.desctxt3")}}<span @click="goIm">{{$t("patient.desctxt4")}}</span>{{$t("patient.desctxt5")}}
                    </p>
                </div>
                <div class="desc-btn" @click="descShow = false">
                    {{$t("patient.descbtn")}}
                </div>
            </div>
        </van-overlay>
        <verification ref="childverification" :tel="patientInfo.tel" v-if="verciftionShow" :verciftionclose="verciftionclose"/>
    </div>
</template>

<script>
import { getPatientInfo, patientInTie, searchIDCard } from "@/api/patient";
import { isPhoneNumber, replacePhone } from "@/utils/vaildate";
import { getYzmCode } from "@/api/user";
import Verification from '../../components/verification.vue';

export default {
    components:{Verification},
    data(){
        return{    
            patientInfo:{},
            replacePhone,
            verciftionShow:false,
            editForm:{},
            timeNum:0,
            descShow:false,
        }
    },
    computed:{
        dislablebtn(){
            return isPhoneNumber(this.patientInfo.tel) && this.editForm.telVerifyCode
        }
    },
    created(){
        this.getinfo()
        document.title = this.$t("patient.bindChange")
    },
    methods:{
        verciftionclose(data){
            if(data.type == 1){
                this.verciftionShow = false;
            }
            if(data.imgVerifyCode && data.type == 2){
                this.getyzmCode(data);
            }
            if(data.type == 3){
                this.getyzmCode({})
            }
        },
        async SecondaryVerification(){
            if(!this.patientInfo.tel){
                this.$toast(this.$t("patient.pleasePhone"));
                return false;
            }
            this.verciftionShow = true;
        },
        async getinfo(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let ids = this.$route.query.bindId;
            if(!this.$route.query.bindId){
                let result = await searchIDCard({idCode:this.$route.query.idCode})
                console.log(result);
                if(result.data.errorCode == 0){
                    ids = result.data.data.id;
                }
            }
            getPatientInfo({id:ids}).then(res=>{
                toast.close();
                if(res.data.data){
                    this.patientInfo = res.data.data.user;
                    this.patientInfo.faceUrl = res.data.data.parentUser.faceUrl;
                    this.patientInfo.tel = res.data.data.parentUser.tel;
                }
            })
        },
        goIm(){
            this.$router.push({
                path:"/im/chat"
            })
        },
        // 验证手机号
        phoneChange(e){
            if(!this.editForm.tel){
                return;
            }
            if(!isPhoneNumber(this.editForm.tel)){
                this.$Toast(this.$t("patient.formatfail"));
                this.editForm.tel = '';
                return;
            }
        },
        // 完成体交
        submit(){
            console.log(this.patientInfo);
            
            if(this.dislablebtn){
                const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
                let datadd = {
                    id:this.$route.query.bindId,
                    tel:this.editForm.tel,
                    telVerifyCode:this.editForm.telVerifyCode
                }
                patientInTie(datadd).then(res=>{
                    toast.close();
                    if(res.data.errorCode == 0){
                        if(localStorage.getItem("addPatientForm")){
                            var formData = JSON.parse(localStorage.getItem("addPatientForm"));
                            // 6418
                            formData['name'] = this.patientInfo.name;
                            formData['gender'] = this.patientInfo.gender;
                            formData['relationship'] = formData['relationship']?formData['relationship']:this.patientInfo.relationship;

                            console.log(this.patientInfo,formData);
                            localStorage.setItem("addPatientForm",JSON.stringify(formData))
                        }
                        this.$router.go(-1);
                        // this.$router.push({
                        //     path:"/patient/index",
                        // })
                    }else{
                        this.$toast(res.data.errorMsg)
                    }
                })
            }
        },
        getyzmCode(data){
            if(isPhoneNumber(this.patientInfo.tel)&&this.timeNum == 0){
                getYzmCode({tel:this.patientInfo.tel,...data}).then(res=>{
                    console.log(res);
                    if(res.data.errorCode == 100001){
                        this.$toast(res.data.errorMsg);
                        setTimeout(()=>{
                            this.$refs.childverification.getImgYzmData();
                        },500)
                        return false;          
                    }else{
                        this.verciftionShow = false;
                    }
                    this.timeNum = 60;
                    let times = setInterval(()=>{
                        this.timeNum --;
                        if(this.timeNum == 0){
                            clearInterval(times);
                        }
                    },1000)
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.patient-editBind{
    min-height: 100%;
    background: #F5F6FA;
    .editBind-main{
        padding: 0 0.6rem;
        background: #fff;
        overflow: auto;
        .last-patient{
            padding-top: 0.6rem;
            .patient-name{
                font-size: 0.56rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .patient-info{
                margin-top: 0.3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    margin-right: 0.4rem;
                }
                .account{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #333333; 
                }
                .info-tag{
                    font-size: 0.44rem;
                    font-family: PingFang SC;
                    font-weight: 300;
                    color: #FF671C;
                    padding: 0.1rem 0.16rem;
                    background: #FFF3E2;
                    margin-left: 0.3rem;
                    border-radius: 0.06rem;
                }
            }
        }
        .logininfo{
            margin-bottom: 0.8rem;
            .patient-info{
                .info-tag{
                    color: #49A7FF;
                    background: #E1EEFD;
                }
            }
        }
        .main-icon{
            margin: 0.8rem auto 0 auto;
            width: 1rem;
            height: 1.4rem;
            background: url("~@/assets/img/patient-arrow.png") no-repeat;
            background-size: 100% 100%;
        }
        .bind-desc{
            background: #FFF3E2;
            border-radius: 0.32rem;
            padding: 1.8rem 0.6rem 0.6rem 0.6rem;
            box-sizing: border-box;
            font-size: 0.56rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 0.84rem;
            position: relative;
            &::after{
                content: " ";
                position: absolute;
                background: url("~@/assets/img/patient-desc-icon.png") no-repeat;
                background-size: 100% 100%;
                top: -0.22rem;
                left: 0.6rem;
                display: block;
                width: 6.2rem;
                height: 1.8rem;
            }
        }
        .edit-form{
            .form-item{
                padding: 0.4rem 0;
                border-bottom: 0.02rem solid #E5E7EC;
                display: flex;
                align-items: center;
                .label{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    flex: 0 0 auto;
                    margin-right: 0.4rem;
                }
                /deep/ .van-field__control{
                    font-size: 0.56rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    
                    
                }
                /deep/ .van-field{
                    border: 0;
                    &::after{
                        display: none;
                    }
                    *{
                        border: 0;
                    }
                }
                .noyzm{
                    font-size: 0.56rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #49A7FF;
                    line-height: 1rem;
                }
                .btn-yzm{
                    border: 0;
                    flex: 0 0 auto;
                    background: #49A7FF;
                    font-size: 0.56rem;
                    border-radius: 0.16rem;
                    padding: 0.2rem 0.2rem;
                    box-sizing: content-box;
                    margin-left: 0.4rem;
                }
                &:last-child{
                    border: 0;
                }
            }
        }
    }
    .edit-btn{
        background: #C1C4CB;
        border-radius: 1rem;
        height: 2rem;
        width: 13.8rem;
        margin: 1rem auto 2rem auto;
        font-size: 0.76rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #919398;
        line-height: 2rem;
        text-align: center;
    }
    .btnActive{
        color: #fff;
        background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
    }
    .mydesc{
        display: flex;
        justify-content: center;
        align-items: center;
        .patientwrapper{
            position: relative;
            padding: 0.8rem;
            margin-top: -2rem;
            background: #FFFFFF;
            border-radius: 0.4rem;
            width: 11.4rem;
            box-sizing: border-box;
            .title{
                font-size: 0.72rem;
                text-align: center;
                font-family: PingFang SC;
                font-weight:600;
                color: #333333;
            }
            .desc{
                padding: 1rem 0;
                .txt{
                    font-size: 0.62rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 0.8rem;
                    margin-bottom:0.6rem;
                    &:last-child{
                        margin: 0;
                    }
                    span{
                       color: #FF671C; 
                       font-weight: 600;
                    }
                }
            }
            .desc-btn{
                width: 8.2rem;
                height: 1.8rem;
                line-height: 1.8rem;
                text-align: center;
                background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
                border-radius: 0.9rem;
                color: #fff;
                margin: 0 auto;
                font-size: 0.72rem;
                margin-bottom: 0.4rem;
                
            }
        }
        .close-icon{
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            bottom: -3rem;
            left: 50%;
            transform: translate(-50%,0);
            background: url("~@/assets/img/tj_close_icon.png") no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>